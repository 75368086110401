@import "https://js.arcgis.com/4.22/@arcgis/core/assets/esri/themes/light/main.css";

@font-face {
  font-family: "SkanskasansRegular";
  src: local("SkanskasansRegular"),
    url("./fonts/skanskasans-regular-webfont.woff") format("woff"),
    url("./fonts/skanskasans-regular-webfont.woff2") format("woff2");
}

@font-face {
  font-family: "SkanskasansLight";
  src: local("SkanskasansLight"),
    url("./fonts/skanskasans-light-webfont.woff") format("woff"),
    url("./fonts/skanskasans-light-webfont.woff2") format("woff2");
}

@font-face {
  font-family: "SkanskasansBold";
  src: local("SkanskasansBold"),
    url("./fonts/skanskasans-bold-webfont.woff") format("woff"),
    url("./fonts/skanskasans-bold-webfont.woff2") format("woff2");
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

:root {
  --main-bk-color: #1978c9;
  --main-bk-color-dark: #293e6b;
  --main-color: #fff;
  --main-color-dark: #000;
}

body {
  margin: 0px;
  padding: 0px 0px 10px 0px;
  overflow: hidden;
}

.arrow-right {
  margin-left: 1rem;
  width: 0;
  height: 0;
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent;
  align-self: center;
  border-left: 12px solid var(--main-bk-color-dark);
  border-radius: 5px;
}

.arrow-left {
  margin-right: 1rem;
  width: 0;
  height: 0;
  align-self: center;
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent;
  border-right: 12px solid var(--main-bk-color-dark);
  border-radius: 5px;
}

.arrow-left:hover {
  cursor: pointer;
  border-right: 12px solid var(--main-bk-color);
}

.arrow-right:hover {
  cursor: pointer;
  border-left: 12px solid var(--main-bk-color);
}
