.header {
  height: 77px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  display: flex;
  background-color: #fbfbfb;
  padding: 0 5%;
  position: sticky;
  border-bottom: 1px solid darkgrey;
  font-family: "SkanskasansRegular";
}

table {
  border-spacing: 12px;
  position: relative;
  top: -15px;
}

td {
  padding-bottom: 0px;
}

.logo {
  font-size: 2rem;
  color: #283d6c;
  font-weight: bold;
}

.header ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.header li {
  margin-left: 2rem;
}

.projectinfo {
  height: 50px;
  width: auto;
  opacity: 0.6;
  font-family: "SkanskasansRegular";
}

.projectinfo ul {
  list-style: none;
  margin: 0;
  padding: 0;
  left: 0;
  flex-direction: column;
}

.projectinfo li {
  align-self: flex-end;
}

.projectinfo div {
  color: #3d3d3d;
  font-family: "SkanskasansRegular";
  font-size: 16px;
  text-align: right;
}

.veginfo {
  height: 21px;
  width: auto;
  flex-grow: 4;
}

.veginfo ul {
  list-style: none;
  margin: 0;
  padding: 0;
  align-items: baseline;
}

.veginfo li {
  margin-left: 3rem;
}

.veginfo div {
  color: #3d3d3d;
  font-family: "SkanskasansRegular";
}

.avstandSenterlinje,
.profilNr {
  font-size: 10px;
  height: 28.16px;
}

.avstandSenterLinjeVerdi,
.prolfilNrVerdi {
  font-size: 16px;
}
