.messageComponent {
 position: absolute;
 z-index: 100;
 background-color: blue;
 bottom: 0px;
 bottom: -50rem;
 left: 0px;
 right: 0px;
 margin: 0 3rem;
 border-radius: 5px 5px 0px 0px;
 display: grid;
 justify-content: center;
 align-items: center;
 font-family: SkanskasansRegular;
}

.messageComponentActive {
    background-color: rgba(248, 143, 143, 0.95);
    bottom: 0rem;

}
