.plot-container {
  border-top: 1px solid rgb(156, 156, 156);
  border-bottom: 1px solid rgb(156, 156, 156);
  background-color: white;
  display: grid;
  grid-template-rows: 54px auto;
  position: relative;
}

.plot-main {
  background-color: rgb(187, 187, 187);
}

.noPlotInfo {
  align-self: center;
  justify-self: center;
  padding: 1rem;
  border: 1px solid grey;
  background-color: white;
  border-radius: 5px;
  font-family: "SkanskasansRegular";
}

.noPlotInfoContainer {
  display: grid;
  background-color: rgb(0, 0, 0, 0.15);
}
