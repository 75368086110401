.buttonRow {}

.button {
    background-color: #F3F5FB;
    width: 3rem;
    height: 2.0rem;
    border: 1px solid black;
    border-width: 0px;
    border-radius: 0px;
}

.button:first-child {

    border-radius: 4px 0px 0px 4px;
}

.button:last-child {

    border-radius: 0px 4px 4px 0px;
}

.buttonSelected {
    background-color: #01395A;
    color:white;
}