@import "https://js.arcgis.com/4.19/@arcgis/core/assets/esri/themes/light/main.css";

#viewDiv {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

/* styling to the div with measurement widgets*/
#toolbarDiv {
  cursor: default;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  color: #01395a !important;
  height: 32px;
}

.toolbarDiv {
  width: 50px;
  height: 50px;
}

.esri-widget *,
.esri-widget *:before,
.esri-widget *:after {
  box-sizing: inherit;
  color: #01395a !important;
  background-color: #f3f5fb !important;
}

/* defines active measurement widget */
.esri-widget--button.active,
.esri-widget--button.active:hover,
.esri-widget--button.active:focus {
  cursor: default;
  border-color: #0269a6 !important;
  border-width: 4px !important;
}
.esri-widget--button.active path,
.esri-widget--button.active:hover path,
.esri-widget--button.active:focus path {
  fill: #e4e4e4 !important;
}

.ruler {
  background-image: url(../../images/linjal.png);
  width: 24px;
  height: 9px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  border-radius: 2px;
}

.layerSettingsButton {
  background-image: url(../../images/layerlist.png);
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  border-radius: 2px;
  border: none;
}

/*styling to basemapToggle*/
.esri-collapse__icon.esri-icon-basemap,
.esri-collapse__icon.esri-icon-basemap:before {
  content: " ";
  background-image: url("../../images/Basemaps.png");
  margin-left: auto;
  margin-right: auto;
  background-repeat: no-repeat;
  background-size: 18px;
  width: 18px;
  height: 18px;
  border-radius: 2px;
}

.esriSimpleSliderTL {
  top: 5px;
  left: 7px;
  border: 0px solid #666;
  background-color: rgba(255, 255, 255, 0.3);
}

.esri-basemap-gallery__item-title {
  font-size: 13px !important;
  color: #01395a !important;
  font-weight: bold;
}

.esri-basemap-gallery__item-thumbnail {
  height: 50px;
  width: 50px;
}

.esri-ui-corner .esri-expand .esri-widget--panel,
.esri-ui-corner .esri-expand .esri-widget--panel-height-only,
.esri-ui-corner .esri-component > .esri-widget--panel,
.esri-ui-corner .esri-component.esri-widget--panel {
  width: 250px !important;
  background-color: #f3f5fb;
}

.esri-basemap-gallery__item--selected,
.esri-basemap-gallery__item.esri-basemap-gallery__item--selected:hover,
.esri-basemap-gallery__item.esri-basemap-gallery__item--selected:focus {
  border-left-color: #01395a !important;
}

.esri-ui-corner .esri-component {
  background-color: #f3f5fb;
}

/*styling of home button*/
.esri-icon-home,
.esri-icon-home:before {
  content: " " !important;
  background-image: url(../../images/hus.png);
  background-repeat: no-repeat;
  background-size: 20px;
  width: 20px;
  height: 20px;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: 5px;
  position: relative;
}

/*styling of zoom buttons*/

.esri-icon-plus,
.esri-icon-plus:before {
  content: " " !important;
  background-image: url(../../images/plus.png);
  background-repeat: no-repeat;
  background-size: 16px;
  width: 16px;
  height: 18px;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: 5px;
  position: relative;
}

.esri-collapse__icon.esri-icon-basemap,
.esri-collapse__icon.esri-icon-basemap:before {
  content: " ";
  background-image: url("../../images/Basemaps.png");
  margin-left: auto;
  margin-right: auto;
  background-repeat: no-repeat;
  background-size: 18px;
  width: 18px;
  height: 18px;
  border-radius: 2px;
}

.esri-icon-minus,
.esri-icon-minus:before {
  content: " " !important;
  background-image: url(../../images/minus.png);
  background-repeat: no-repeat;
  background-size: 18px;
  width: 16px;
  height: 18px;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  position: relative;
}

#checkboxDiv {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 14px;

  position: absolute;
  width: 180px;
  height: 32px;
  right: 70px;
  top: 14px;

  background: #f3f5fb;
  /* Map buttons */

  box-shadow: 1px 4px 14px rgba(0, 0, 0, 0.14);
  border-radius: 4px;
}
.checkboxLayout {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;

  position: static;
  width: 152px;
  height: 8px;
  left: 14px;
  top: 14px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 0px;
}
.checkboxBox {
  /* checkbox */

  position: static;
  left: 0%;
  right: 89.47%;
  top: 0%;
  bottom: 0%;

  background: #ffffff;
  border-radius: 2px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 8px;
}

.checkboxLabel {
  /* indeterminate */

  position: static;
  width: 128px;
  left: calc(50% - 128px / 2 + 12px);
  top: 12.5%;
  bottom: 12.5%;

  /* Body/Supersmall Regular */

  font-family: Sofia Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 12px;
  /* identical to box height */

  display: flex;
  align-items: center;

  /* Neutral 90 */

  color: #474b54;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 8px;
}

#rightSideTools {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
}
.esri-popup__main-container {
  background-color: #f3f5fb !important;
}

.esri-feature__content-element {
  padding: 0 7px !important;
  margin-bottom: 24px !important;
  column-gap: 3% !important;
  display: inline-block !important;
  margin-top: 14px !important;
  font-style: inherit !important;
  font-size: larger !important;
}
