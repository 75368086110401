.input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.button_image {
    border: 1px solid blue;
    width: 35px;
    border-radius: 5px;
    filter:  grayscale(1);
}

.input:checked+.button_image {
    filter: grayscale(0);
}


.toolbar {
    display: flex;
    gap:30px;
    margin-left: 50px;
}


