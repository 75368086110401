.plotHeader {
    display: grid;
    border-bottom: 0px solid gray;
    grid-template-columns: 2fr 1fr 2fr;
    grid-template-rows: 10px 1fr;
    align-items: center;
}

.plottHandle {
    grid-column: span 3;
    justify-self: center;
    align-self: center;
    /*background-color: gray;*/
}

.plottHandleHr {
    border: 1px solid grey;
    border-radius: 1px;
    width: 100px;
    margin: 0px;
    padding: 0;
    cursor: ns-resize;
}

.plotHeaderTitle {
    padding-left: 1rem;
}

.plotHeaderProfilnummer {
    justify-self: center;
    display: flex;
}

.plotHeaderTools {
    background-color: white;
    justify-self: center;
    display: flex;
    gap: 25px;
}

.profilnummerInput {
    border-color: rgba(0, 0, 0, 0.267);
    border-radius: 5px;
    text-align: center;
    width: 6rem;
    height: 2.0rem;
    color: var(--main-bk-color-dark);
    margin: 6px 0px;
} 