.measureToolbar {
  position: absolute;
  top: 60px;
  left: 70px;
  z-index: 1000;
  display:flex;
  gap:5px;
}

.button {
  
  height: 32px;
  width: 32px;
  border-width: 0px;
  
  border-radius: 5px;
  cursor: auto;
  filter: contrast(50%) brightness(150%);
  
}

.hidden {
    display: none;
}

.start {
    background-image: url("../../images/MeasureStart.png");
}

.add {
  background-image: url("../../images/MeasureStart.png");
}

.stop {
    background-image: url("../../images/MeasureStop.png");
  
}

.up {
    filter: contrast(100%) brightness(100%);
    cursor: pointer;
}
