#layerSettingsContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 280px;
  height: 843px;
  overflow-y: scroll;
  box-shadow: -4px 0px 12px rgba(0, 0, 0, 0.04);
  background-color: white;
  overflow-x: hidden;
  overflow-y: auto;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 8px 12px 16px;
  position: static;
  width: 280px;
  height: 51px;
  left: 0px;
  top: 0px;
}
.header-text {
  position: static;
  width: 220px;
  height: 27px;
  left: 16px;
  top: 12px;

  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 136.02%;
  color: #01395a;
}

.header-exit {
  background-image: url(../../images/exit.png);
  background-repeat: no-repeat;
  background-position: center;
  position: static;
  width: 12px;
  height: 12px;
  left: 57px;
  top: 0px;
  background-color: white;
  border: none;
}

.header-exit:hover {
  background-color: #f3f5fb;
}

.subheader {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 8px 10px 16px;

  position: static;
  width: 280px;
  height: 36px;
  left: 0px;
  top: 261px;

  /* Neutral 12 */

  background: #f3f5fb;

  /* Inside auto layout */

  flex: none;
  order: 3;
  align-self: stretch;
  flex-grow: 0;
  margin: 0px 0px;
}

.subheader-text {
  position: static;
  width: 221px;
  height: 14px;
  left: 16px;
  top: 11px;

  /* Body/Small Semibold */

  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  /* identical to box height */

  font-feature-settings: "salt" on;

  /* Secondary */

  color: #01395a;
}

.subheader-expand {
  background-image: url(../../images/arrow_down.png);
  background-repeat: no-repeat;
  background-position: center;
  position: static;
  width: 10px;
  height: 10px;
  left: 256px;
  top: 10px;
  border: none;
  background-color: #f3f5fb;
}
.subheader-expand:hover {
  background-color: #e6e8ec;
}

.content-overbygningslag {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px 8px 8px 14px;

  position: static;
  width: 221px;
  height: auto;
  left: 0px;
  top: 85px;
  background-color: white;

  flex: none;
  order: 2;
  align-self: stretch;
  flex-grow: 0;
  margin: 0px 0px;
}
.content-layer-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px 8px 8px 14px;

  position: static;
  width: 280px;
  height: auto;
  left: 0px;
  top: 297px;
  background-color: white;

  flex: none;
  order: 4;
  align-self: stretch;
  flex-grow: 0;
  margin: 0px 0px;
}
